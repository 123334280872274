import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import { useNavigate } from "react-router-dom";

const MapContainer = ({ google, center, zoom, markers, height, width}, props) => {
  const [map, setMap] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  const navigate = useNavigate();

  // console.log("Markers : ", markers);
  // console.log("Center : ", center);
  // console.log("props : ", markers);
  const onMapReady = (_, map) => {
    // console.log("Map ready:", map);
    setMap(map);
  };

//   function createMapOptions (maps) {
//     return {
//         zoomControl: false,
//         mapTypeControl: false,
//         scaleControl: false,
//         streetViewControl: false,
//         rotateControl: false,
//         fullscreenControl: false,
//         mapTypeControl: false,
//     }
// }

  // useEffect(() => {
  //   if (map && center && typeof center.lat === "number" && typeof center.lng === "number") {
  //     console.log("Panning to center:", center);
  //     map.panTo(center);
  //   }
  // }, [map, center]);

  const onMarkerClick = (props, marker, e) => {
    console.log("props : ", props);
    console.log("Marker : ", marker);
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onMapClicked = (props) => {
      if (showingInfoWindow) {
          setShowingInfoWindow(false);
          setActiveMarker(null);
      }
  };

  const handleMarkerClick = (markerr, marker) => {
    console.log("Marker on click : ", markerr);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
    setSelectedPlace(markerr);
    // Check the type of marker (assuming marker.type exists)
    // if (marker?.type === "substation") {
    //     navigate(`/substationdetail/${marker?.id}`);
    // } else if (marker?.type === "transformer") {
    //     navigate(`/transformerdetail/${marker?.id}`);      
    // } else if (marker?.type === "meter") {
    //   console.log("meter");
    //    navigate(`/meterdetail/${marker?.id}`);
    // } else if (marker?.type === "charger") {
    //   navigate(`/chargerdetail/${marker?.id}`);
    // } else {
    //   // Default action
    //   console.log("Marker clicked:", marker);
    // }
  };

  return (
    <div style={{ width: 'calc(100% - 285px)', height: '100%'}}>                
        <Map 
            google={google} 
            // options={createMapOptions}
            center={center}
            initialCenter={center}   
            zoom={zoom} 
            onReady={onMapReady}
            onClick={onMapClicked}
            fullscreenControl={false}
            mapTypeControl={false}
            // zoomControl={false}
            scaleControl={false}
            streetViewControl={false}
            style={{height: height, width: width}}
        >
            {markers && markers.map((marker, index) => {
                if (marker && typeof marker.lat === "number" && typeof marker.lng === "number") {
                  return (
                    <Marker 
                      key={index+1}  
                      position={{ lat: marker.lat, lng: marker.lng }} 
                      icon={{
                          url: marker?.icon,
                          // anchor: new props.google.maps.Point(7.5, 10),
                          // scaledSize: new google.maps.Size(marker?.type === "meter" ? 52 : 34, marker?.type === "meter" ? 52 : 34),
                      }}
                      // onClick={() => handleMarkerClick(marker)} // Add onClick event handler
                      onClick={onMarkerClick}
                      station={marker}
                    />
                  );
                } else {
                console.log("Invalid marker:", marker);
                return null;
                }
            })}
            <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
              <div className='map_popup_div d-flex'>                    
                <div className='map_popup_div2' style={{width: '350px'}}>
                  {/* <p>asdfald lasdjf ladfkl</p> */}
                    {selectedPlace?.station?.type === "transformer" && (
                      <>
                        <h4>Transformer</h4>
                        <p className='mapb-s1 mb-0 w-100'>ID: {selectedPlace?.station?.data?.transID}  </p>
                        <p className='mapb-s1 mb-0 w-100'>Name: {selectedPlace?.station?.data?.transName}  </p>
                        <p className='mapb-s1 mb-0 w-100'>Address: {selectedPlace?.station?.data?.transAddress}  </p>
                        {/* <button className="view-btn" onClick={() => navigate(`/transformerdetail/${selectedPlace?.station?.data?.id}`)}>View</button> */}
                        <a className="view-btn" target="_blank" href={`/transformerdetail/${selectedPlace?.station?.id}`}>View</a>
                      </>
                    )}
                    {selectedPlace?.station?.type === "substation" && (
                      <>
                        <h4>SubStation</h4>
                        <p className='mapb-s1 mb-0 w-100'>ID: {selectedPlace?.station?.data?.substationsID}  </p>
                        <p className='mapb-s1 mb-0 w-100'>Name: {selectedPlace?.station?.data?.substationName}  </p>
                        <p className='mapb-s1 mb-0 w-100'>Address: {selectedPlace?.station?.data?.substationAddress}  </p>
                        {/* <button className="view-btn" onClick={() => navigate(`/substationdetail/${selectedPlace?.station?.data?.id}`)}>View</button> */}
                        <a className="view-btn" target="_blank" href={`/substationdetail/${selectedPlace?.station?.id}`}>View</a>
                      </>
                    )}
                    {selectedPlace?.station?.type === "meter" && (
                      <>
                        <h4>Meter</h4>
                        <p className='mapb-s1 mb-0 w-100'>ID: {selectedPlace?.station?.data?.meterID}  </p>
                        <p className='mapb-s1 mb-0 w-100'>Name: {selectedPlace?.station?.data?.meterName}  </p>
                        <p className='mapb-s1 mb-0 w-100'>Address: {selectedPlace?.station?.data?.meterAddress}  </p>
                        <a className="view-btn" target="_blank" href={`/meterdetail/${selectedPlace?.station?.id}`}>View</a>
                      </>
                    )}
                    {selectedPlace?.station?.type === "charger" && (
                      <>
                        <h4>Charger</h4>
                        <p className='mapb-s1 mb-0 w-100'>ID: {selectedPlace?.station?.data?.chargerID}  </p>
                        <p className='mapb-s1 mb-0 w-100'>Name: {selectedPlace?.station?.data?.chargerName}  </p>
                        <p className='mapb-s1 mb-0 w-100'>Address: {selectedPlace?.station?.data?.chargerAddress}  </p>
                        {/* <button className="view-btn" onClick={() => navigate(`/chargerdetail/${selectedPlace?.station?.id}`)}>View</button> */}
                        <a className="view-btn" target="_blank" href={`/chargerdetail/${selectedPlace?.station?.id}`}>View</a>
                      </>
                    )}
                </div>
                </div>
            </InfoWindow>
        </Map>
    </div>
  );
};


{/* <div className="map-div">
    <div>
        <div>
            <div className="inr-div">
                <div></div>
            </div>
        </div>
    </div>
</div> */}


export default GoogleApiWrapper({
  apiKey: 'AIzaSyAQF_bRb6_yfKjJmAW4EFL3VLrC07tl5i4',
})(MapContainer);
